window.addEventListener("DOMContentLoaded", (event) => {

    event.preventDefault();

    var dropdown = document.querySelectorAll('.dropdown-toggle');

    /* 
     * Mutation Observer for main nav menu
     * Adds transitions to the Bootstrap nav when displaying the dropdown submenus
     * This is determined by observing the 'show' class which Bootstrap adds/removes to its dropdown menus
     */

    dropdown.forEach((item) => {
        var elemToObserve = item.nextElementSibling; //observe the submenu ul
        var prevClassState = elemToObserve.classList.contains('show');
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if(mutation.attributeName == "class"){
                    var currentClassState = mutation.target.classList.contains('show');
                    if(prevClassState !== currentClassState)    {
                        prevClassState = currentClassState;
                        if(currentClassState) {
                            setTimeout(function() {
                                mutation.target.classList.add('appear');
                                setTimeout(function() {
                                    mutation.target.classList.add('block');
                                }, 50);
                            }, 50);
                            item.classList.add('expanded');
                        } else {
                            mutation.target.classList.remove('appear');
                            setTimeout(function() {
                                mutation.target.classList.remove('block');
                            }, 50);
                            item.classList.remove('expanded');
                        }
                    }
                }
            });
        });
        observer.observe(elemToObserve, {attributes: true});
    });


    /*
     * Search bar (desktop only)
     */

    // Click event listener - search icon click
    document.getElementById("search-toggle").addEventListener("click", toggleSearchBar);

    // Click event listener - outside click
    document.addEventListener("click", toggleHandler);

    // Click event listener - nav items click
    dropdown.forEach((item) => {
        item.addEventListener("click", toggleHandler);
    });

    // Hide search bar when switching focus to elsewhere in the DOM
    document.getElementById('menu-search').querySelector(".search-submit").addEventListener("focusout", function(e) {
        if(document.getElementById('menu-search').querySelector(".search-field") !== e.relatedTarget) {
            if (document.getElementById('search-toggle').classList.contains('clicked')) {
                toggleSearchBar();
            }
        }
    });
    document.getElementById('menu-search').querySelector(".search-field").addEventListener("focusout", function(e) {
        if(document.getElementById('menu-search').querySelector(".search-submit") !== e.relatedTarget) {
            if (document.getElementById('search-toggle').classList.contains('clicked')) {
                toggleSearchBar();
            }
        }
    });
    document.getElementById("search-toggle").addEventListener("focusout", function(e) {
        if(document.getElementById('menu-search').querySelector(".search-field") !== e.relatedTarget) {
            if (document.getElementById('search-toggle').classList.contains('clicked')) {
                toggleSearchBar();
            }
        }
    });

    // Function to toggle the displaying of the search bar
    function toggleSearchBar() {
        var searchBar = document.getElementById("menu-search");
        if (searchBar.classList.contains('hidden')) {
            document.getElementById("search-toggle").classList.add('clicked');
            searchBar.classList.remove('hidden');
            searchBar.classList.add('appeared');
            searchBar.classList.remove('no-show');
        } else {
            document.getElementById("search-toggle").classList.remove('clicked');
            searchBar.classList.replace('appeared','disappeared');
            searchBar.classList.add('no-show');
            setTimeout(function() {
                searchBar.classList.add('hidden');
                searchBar.classList.remove('disappeared');
            }, 500);
        }
    }

    // Function to handle the click event listeners
    function toggleHandler(event) {
        event.preventDefault;
        var searchIcon = document.getElementById("search-toggle");
        var searchBar = document.getElementById("menu-search");
        if (!searchIcon.contains(event.target)) {
            if (!searchBar.contains(event.target)) {
                if (searchIcon.classList.contains('clicked')) {
                    toggleSearchBar();
                }
            }
        }
    }

    /*
     * Hamburger menu (mobile only)
     */

    document.getElementById("navbar-toggler").addEventListener("click", function() {
        if (this.classList.contains('expanded')) {
            this.classList.remove('expanded');
            document.body.classList.remove('no-scroll');
        } else {
            this.classList.add('expanded');
            document.body.classList.add('no-scroll');
        }
    });

    // Make mobile menu scrollable
    if (window.matchMedia("(max-width: 991px)").matches) {
        document.getElementById('main-nav').classList.add('navbar-nav-scroll');
    }

    if (window.matchMedia("(min-width: 992px)").matches) {
        document.getElementById('main-nav').classList.remove('navbar-nav-scroll');
    }

    window.matchMedia('(max-width: 991px)').addEventListener('change', (query) => {
        if(query.matches) {
            document.getElementById('main-nav').classList.add('navbar-nav-scroll');
        } else {
            document.getElementById('main-nav').classList.remove('navbar-nav-scroll');
        }
    });

    // Collapse menu on outside click
    document.addEventListener("click", function(event) {
        if (document.getElementById("main-content").contains(event.target)) {
            if (document.getElementById("bs-example-navbar-collapse-1").classList.contains('show')) {
                document.getElementById("navbar-toggler").click();
            }
        }
    });

    /*
     * Handle 'escape' key press on either mobile or desktop
     */

    // Define functions for 'escape' key press

    function escapeKeyPressMobile() {
        document.onkeydown = function(event) {
            if (event.keyCode == 27) {
                if (document.getElementById("navbar-toggler").classList.contains('expanded')) {
                    document.getElementById("navbar-toggler").click();
                    document.getElementById("navbar-toggler").focus();
                }
            }
        }
    }

    function escapeKeyPressDesktop() {
        document.onkeydown = function(event) {
            if (event.keyCode == 27) {
                if (document.getElementById("search-toggle").classList.contains('clicked')) {
                    toggleSearchBar();
                }
            }
        }
    }

    // Mobile - Collapse menu on 'Escape' button key press
    if (window.matchMedia("(max-width: 991px)").matches) {
        escapeKeyPressMobile();
    }

    // Desktop - Hide search bar on 'Escape' button key press
    if (window.matchMedia("(min-width: 992px)").matches) {
        escapeKeyPressDesktop();
    }

    // Listen for screen resize changes
    window.matchMedia('(max-width: 991px)').addEventListener('change', (query) => {
        if(query.matches) {
            escapeKeyPressMobile();
        } else {
            escapeKeyPressDesktop();
        }
    });

});