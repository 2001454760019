window.addEventListener("DOMContentLoaded", (event) => {

    event.preventDefault();

    // Load gsap scroll trigger plugin
    gsap.registerPlugin(ScrollTrigger);

    // gsap navbar animation
    var navBarAnimate = gsap.from('.header', {
        yPercent: -100,
        paused: true,
        duration: 0.2
    }).progress(1);

    ScrollTrigger.create({
        start: "top -8%",
        end: 99999,
        onUpdate: (self) => {
            self.direction === -1 ? navBarAnimate.play() : navBarAnimate.reverse()
        }
    });
    
    // Load gsap homepage animations if on homepage
    if (document.body.classList.contains('home')) {

        let mm = gsap.matchMedia();

        // add a media query. When it matches, the associated function will run
        mm.add("(max-width: 991px)", () => {
            // this setup code only runs when viewport is at most 991px wide (i.e. mobile)
            var tl = gsap.timeline({ defaults: {opacity: 0, ease: "power1.out"} } );
            tl.from("#primary-feature .diamond-collection", {y: -150, duration: 0.7, delay: 0.5})
            .from("#primary-feature .cta", {y: 50, duration: 0.5}, ">-0.5");

            //Scroll Triggers
            if (document.getElementById("secondary-feature") != null) {
                gsap.from("#secondary-feature .diamond-collection", {
                    scrollTrigger: {
                        trigger: "#secondary-feature .diamond-collection",
                        start: "top 60%",
                        toggleActions: "play none none none"
                    },
                    x: 300,
                    duration: 1.5,
                    opacity: 0,
                    ease: "power1.out"
                });
                gsap.from("#secondary-feature .cta", {
                    scrollTrigger: {
                        trigger: "#secondary-feature .cta",
                        toggleActions: "play none none none"
                    },
                    y: 50,
                    duration: 0.7,
                    opacity: 0,
                    ease: "power1.out"
                });
            }

            // load flexible content sections
            let fadeIn = gsap.utils.toArray('.flexible-content');
            fadeIn.forEach((item) => {
                let tl_flex_content = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start:"top 90%"
                    }
                });
                tl_flex_content.from(item, {y: 50, duration: 1, opacity: 0, ease: "power1.easeOut" });
            });

            // load partner logos
            var tl_logos = gsap.timeline({
                scrollTrigger: {
                    trigger: ".partners-container",
                    start: "top 80%"
                }
            });
            tl_logos.from(".key-partners", {y: 50, duration: 1, opacity: 0, ease: "power1.easeOut" });
        });

        // add a media query. When it matches, the associated function will run
        mm.add("(min-width: 992px)", () => {
            // this setup code only runs when viewport is at least 992px wide (i.e. desktop)
            var tl = gsap.timeline();
            tl.from("#primary-feature .diamond-collection", {
                xPercent: 30,
                opacity: 0,
                duration: 1,
                ease: "power1.easeOut"
            });
            tl.from("#primary-feature .cta", {
                yPercent: 50,
                opacity: 0,
                duration: 1,
                ease: "power1.easeOut"
            }, ">-0.3");

            if (document.getElementById("secondary-feature") != null) {
                var tl1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".feature",
                        start: "top top",
                        end: "+=2000",
                        scrub: 0.5,
                        pin: true,
                        anticipatePin: 1
                    }
                });
            } else {
                var tl1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".feature",
                        start: "top top",
                        end: "+=400",
                        scrub: 0.5,
                        pin: true,
                        anticipatePin: 1
                    }
                });
            }

            tl1.to("#primary-feature .cta", {y: -800, duration: 1, ease: "power1.easeOut"});
            if (document.getElementById("secondary-feature") != null) {
                tl1.from("#secondary-feature .cta", {y: 500, autoAlpha: 0, duration: 1, ease: "power1.easeOut" }, ">-0.5");
                tl1.from("#secondary-feature .main-diamond", {autoAlpha: 0, duration: 1, ease: "power1.easeOut" }, ">-1");
            }

            // load flexible content sections
            let fadeIn = gsap.utils.toArray('.flexible-content');
            fadeIn.forEach((item) => {
                let tl_flex_content = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start:"top 90%"
                    }
                });
                tl_flex_content.from(item, {y: 50, duration: 1, opacity: 0, ease: "power1.easeOut" });
            });
        });

        // reset scroll to beginning upon page refresh
        window.addEventListener("load", () => {
            ScrollTrigger.clearScrollMemory();
            window.history.scrollRestoration = "manual";
        });
        
        // later, if we need to revert all the animations/ScrollTriggers...
        // mm.revert();
    }
});