/* Initially hide all content to prevent FOUC */
document.querySelector('html').classList.add('hidden');

window.addEventListener("DOMContentLoaded", (event) => {

    event.preventDefault();
    
    /* Once DOM loads, first hide any elements which need to be hidden from view */

    // hide searchbar
   document.getElementById("menu-search").classList.add('hidden');
   document.getElementById("menu-search").classList.add('no-show');
    var dropdownMenus = document.querySelectorAll(".dropdown-menu");
    dropdownMenus.forEach(element => {
        element.classList.add("base-opacity");
    });

    // If on the homepage, hide secondary feature elements on load (only applicable for desktop)
    // these will reappear accordingly on scroll using GSAP ScrollTrigger
    if (document.getElementById("secondary-feature") != null) {
        if (document.body.classList.contains('home')) {
            let mm = gsap.matchMedia();
            mm.add("(min-width: 992px)", () => {
                document.getElementById("secondary-feature").querySelector(".main-diamond").classList.add('hidden');
                document.getElementById("secondary-feature").querySelector(".cta").classList.add('hidden');
                document.getElementById("secondary-feature").querySelectorAll(".diamonds").forEach((diamond) => {
                    diamond.classList.add('hidden');
                });
                document.getElementById("secondary-feature").classList.add('absolute-positioning');
            });
            // remove any hidden classes on the secondary feature elements on mobile (i.e when screen resizing)
            mm.add("(max-width: 991px)", () => {
                if (document.getElementById("secondary-feature").querySelector(".main-diamond").classList.contains('hidden')) {
                    document.getElementById("secondary-feature").querySelector(".main-diamond").classList.remove('hidden');
                }
                if (document.getElementById("secondary-feature").querySelector(".cta").classList.contains('hidden')) {
                    document.getElementById("secondary-feature").querySelector(".cta").classList.remove('hidden');
                }
                document.getElementById("secondary-feature").querySelectorAll(".diamonds").forEach((diamond) => {
                    if ((diamond).classList.contains('hidden')) {
                        diamond.classList.remove('hidden');
                    }
                });
                if (document.getElementById("secondary-feature").classList.contains('absolute-positioning')) {
                    document.getElementById("secondary-feature").classList.remove('absolute-positioning');
                }
            });
        }
    }

    // For mobile, correctly calculate and define true inner height in vh units, i.e. the viewport minus browser bar height
    if (window.matchMedia("(max-width: 991px)").matches) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // On resize execute the same script as before
        window.addEventListener('change', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    /* then reveal the page */
    document.querySelector('html').classList.remove('hidden');

});